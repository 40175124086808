export default [
  {
    id: "61df94c2e66e6f1a7b46d9b4",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Bandung",
    compName: "CV. Sungai Indah",
    compID: "631afa3962d22b32840822a7",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede40cb267f9c9e60e2727",
    lampiranBeritaAcara:
      "/storage/16739282640891_Berita_Acara_Verifikasi_KLH_CV_Sungai_Indah.PDF",
    tanggalBeritaAcara: "1630488600",
    tanggalKunjungan: "1630429200",
    status:"terdaftar"
  },
  {
    id: "63b24258aa218e7e4e4d4c69",
    provName: "Sumatera Selatan",
    kabkotName: "Kabupaten Muara Enim",
    compName: "PT. Prima Mulia Sarana Sejahtera",
    compID: "629879e3e7985f5b1182f568",
    provID: "5deda77db267f9c9e60d2b58",
    kabkotID: "5dedd742b267f9c9e60df2af",
    lampiranBeritaAcara:
      "/storage/1672626761263BA_Verlap_SPARING_PT_PMSS_25_28_Des.pdf",
    tanggalBeritaAcara: "1672074000",
    tanggalKunjungan: "1671901200",
    status:"terdaftar"
  },
  {
    id: "63b242b9aa218e7e4e4d51cb",
    provName: "Sumatera Selatan",
    kabkotName: "Kabupaten Muara Enim",
    compName: "PT. MANAMBANG MUARA ENIM",
    compID: "631ebe5d62d22b328430dda0",
    provID: "5deda77db267f9c9e60d2b58",
    kabkotID: "5dedd742b267f9c9e60df2af",
    lampiranBeritaAcara:
      "/storage/1672626848334BA_Verlap_SPARING_PT_MME_25_28_Des.pdf",
    tanggalBeritaAcara: "1672074000",
    tanggalKunjungan: "1703437200",
    status:"terdaftar"
  },
  {
    id: "63c60cb7aa218e7e4e743f97",
    provName: "Jawa Timur",
    kabkotName: "Kabupaten Banyuwangi",
    compName: "PT BUMI SUKSESINDO",
    compID: "62394c31ee1d7f074377c369",
    provID: "5deda857b267f9c9e60d2f13",
    kabkotID: "5dededdcb267f9c9e60e503b",
    lampiranBeritaAcara: "/storage/1673923765891BA_PT_BSI.pdf",
    tanggalBeritaAcara: "1672074000",
    tanggalKunjungan: "1671901200",
    status:"terdaftar"
  },
  {
    id: "63c60d0caa218e7e4e7448a5",
    provName: "Kalimantan Timur",
    kabkotName: "Kabupaten Kutai Kartanegara",
    compName: "PT Multi Harapan Utama",
    compID: "5f572c1d36b37d2585a64c52",
    provID: "5deda903b267f9c9e60d3216",
    kabkotID: "5dee1920b267f9c9e60f02d5",
    lampiranBeritaAcara: "/storage/1673923850368img20221229_15400253.pdf",
    tanggalBeritaAcara: "1672074000",
    tanggalKunjungan: "1671901200",
    status:"terdaftar"
  },
  {
    id: "63c6115baa218e7e4e748ed3",
    provName: "Kalimantan Selatan",
    kabkotName: "Kabupaten Balangan",
    compName: "PT. Semesta Centramas",
    compID: "62a6f17b0432880d6a2f4c66",
    provID: "5deda8efb267f9c9e60d31c5",
    kabkotID: "5dee04edb267f9c9e60eae82",
    lampiranBeritaAcara: "/storage/1673924954638Verlap_SPARING_PT_LSA_SCM.pdf",
    tanggalBeritaAcara: "1671123600",
    tanggalKunjungan: "1670864400",
    status:"terdaftar"
  },
  {
    id: "63c6119aaa218e7e4e749282",
    provName: "Kalimantan Selatan",
    kabkotName: "Kabupaten Balangan",
    compName: "PT. Laskar Semesta Alam",
    compID: "62a80f210432880d6a30e5b6",
    provID: "5deda8efb267f9c9e60d31c5",
    kabkotID: "5dee04edb267f9c9e60eae82",
    lampiranBeritaAcara:
      "/storage/1673925011086Verlap_SPARING_PT_LSA_SCM_Des_2022.pdf",
    tanggalBeritaAcara: "1671037200",
    tanggalKunjungan: "1670864400",
    status:"terdaftar"
  },
  {
    id: "63c61387aa218e7e4e74b01b",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Purwakarta",
    compName: "PT. Besland Pertiwi",
    compID: "623ae506ee1d7f0743799f22",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede495b267f9c9e60e2987",
    lampiranBeritaAcara:
      "/storage/16739255083471_BA_scan_laporan_PT_BEsland.pdf",
    tanggalBeritaAcara: "1631206800",
    tanggalKunjungan: "1631120400",
    status:"terdaftar"
  },
  {
    id: "63c615eaaa218e7e4e74d27d",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Bandung Barat",
    compName: "PT. Indo-Rama Synthetics, Tbk. - Bandung",
    compID: "61b059144ae93e096ad15b41",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4c2b267f9c9e60e2a44",
    lampiranBeritaAcara:
      "/storage/16739261181371_BA_PT_INDORAMA_SYNTHETICS.pdf",
    tanggalBeritaAcara: "1631034000",
    tanggalKunjungan: "1630947600",
    status:"terdaftar"
  },
  {
    id: "63c61658aa218e7e4e74d6f1",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Purwakarta",
    compName: "PT. Indo-rama Synthetics, Tbk. - Purwakarta",
    compID: "611f7344e129591985dcacba",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede495b267f9c9e60e2987",
    lampiranBeritaAcara: "/storage/16739262309471_INDO_RAMA_BA.pdf",
    tanggalBeritaAcara: "1630947600",
    tanggalKunjungan: "1630861200",
    status:"terdaftar"
  },
  {
    id: "63c616f4aa218e7e4e74dfef",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Sumedang",
    compName: "PT Kahatex Sumedang",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede468b267f9c9e60e28d2",
    lampiranBeritaAcara: "/storage/1673926384683KAHATEX_1.pdf",
    tanggalBeritaAcara: "1630429200",
    tanggalKunjungan: "1630342800",
    status:"terdaftar"
  },
  {
    id: "63c61738aa218e7e4e74e31d",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT. Mitra Karawangjaya",
    compID: "62440e27462c3e3beaed667c",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara:
      "/storage/16739264535751_BA_KAWASAN_INDUSTRI_MITRAKARAWANG.pdf",
    tanggalBeritaAcara: "1630515600",
    tanggalKunjungan: "1630429200",
    status:"terdaftar"
  },
  {
    id: "63c61d0eaa218e7e4e7538ff",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT Pindo Deli 1",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara: "/storage/16739266445951_BA_VERLAP_PINDO_DELI_1.pdf",
    tanggalBeritaAcara: "1630256400",
    tanggalKunjungan: "1630170000",
    status:"terdaftar"
  },
  {
    id: "63c61da2aa218e7e4e75432b",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT Pindo Deli 2",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara: "/storage/16739280962151_BA_VERLAP_PINDO_DELI_2.pdf",
    tanggalBeritaAcara: "1630947600",
    tanggalKunjungan: "1630861200",
    status:"terdaftar"
  },
  {
    id: "63c61e12aa218e7e4e754999",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT Pindo Deli 3",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara: "/storage/16739281459581_BA_VERLAP_PINDO_DELI_3.pdf",
    tanggalBeritaAcara: "1631034000",
    tanggalKunjungan: "1630947600",
    status:"terdaftar"
  },
  {
    id: "63c620adaa218e7e4e756fa7",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Bandung",
    compName: "PT. Gistex (Lagadar)",
    compID: "628dea15e7985f5b117174cf",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede40cb267f9c9e60e2727",
    lampiranBeritaAcara: "/storage/16739288302691_BA_GISTEX.pdf",
    tanggalBeritaAcara: "1630947600",
    tanggalKunjungan: "1630861200",
    status:"terdaftar"
  },
  {
    id: "63c6216caa218e7e4e75797c",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Bandung",
    compName: "PT. Pulau Mas Texindo",
    compID: "63217296110bec4f64818aa1",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede40cb267f9c9e60e2727",
    lampiranBeritaAcara: "/storage/16739290662581_BA_PULAU_MAS_TEXINDO.pdf",
    tanggalBeritaAcara: "1630602000",
    tanggalKunjungan: "1630515600",
    status:"terdaftar"
  },
  {
    id: "63c621ceaa218e7e4e758022",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT. Indotaisei Indah Development",
    compID: "623c3491ee1d7f07437b8bb4",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara:
      "/storage/16739291642961_BA_PT_INDOTAISEI_INDAH_DEVLOPMENT.pdf",
    tanggalBeritaAcara: "1630429200",
    tanggalKunjungan: "1630342800",
    status:"terdaftar"
  },
  {
    id: "63c6221aaa218e7e4e7585a8",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Purwakarta",
    compName: "PT. South Pacific Viscose",
    compID: "614ae061eeda6749facaab64",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede495b267f9c9e60e2987",
    lampiranBeritaAcara: "/storage/16739292400571_BA_SPVI.pdf",
    tanggalBeritaAcara: "1630515600",
    tanggalKunjungan: "1630429200",
    status:"terdaftar"
  },
  {
    id: "63c6234faa218e7e4e75955f",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Karawang",
    compName: "PT. Suryacipta Swadaya",
    compID: "621ee50cee1d7f07435d6c09",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede4a5b267f9c9e60e29d9",
    lampiranBeritaAcara: "/storage/16739294158051_BA_SURYACIPTA_SWADAYA.pdf",
    tanggalBeritaAcara: "1631725200",
    tanggalKunjungan: "1631638800",
    status:"terdaftar"
  },
  {
    id: "63c8f7ebaa218e7e4ea4cd44",
    provName: "Jawa Barat",
    kabkotName: "Kota Bekasi",
    compName: "PT Darmex Oil & Fats",
    compID: "6295b7c1e7985f5b117e91d1",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede533b267f9c9e60e2c39",
    lampiranBeritaAcara:
      "/storage/1674115046750Verlap_SPARING_PT_Darmex_Oil_Fats.pdf",
    tanggalBeritaAcara: "1671987600",
    tanggalKunjungan: "1671987600",
    status:"terdaftar"
  },
  {
    id: "63c8f817aa218e7e4ea4cfe3",
    provName: "Jawa Barat",
    kabkotName: "Kota Bekasi",
    compName: "PT Darmex Biofuels",
    compID: "631eb95262d22b32843098f9",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede533b267f9c9e60e2c39",
    lampiranBeritaAcara:
      "/storage/1674115093205Verlap_SPARING_PT_Darmex_Biofuels.pdf",
    tanggalBeritaAcara: "1672074000",
    tanggalKunjungan: "1672074000",
    status:"terdaftar"
  },
  {
    id: "64af77a896ed275ec837583e",
    provName: "Jawa Barat",
    kabkotName: "Kabupaten Indramayu",
    compName: "PT. Pertamina EP Asset 3 - Field Jatibarang",
    compID: "63102680bb11776a058d778c",
    provID: "5deda806b267f9c9e60d2db6",
    kabkotID: "5dede477b267f9c9e60e28f0",
    lampiranBeritaAcara:
      "/storage/168922103069613072023_BERITA_ACARA_VERIFIKASI_LAPANGAN_IMPLEMENTASI_SPARING_PEP_Jatibarang.pdf",
    tanggalBeritaAcara: "1688662800",
    tanggalKunjungan: "1688576400",
    status:"terdaftar"
  },
];
