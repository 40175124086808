<template>
  <div class="content">
    <div>
      <div>
        <b-col
          cols="12"
          class="mb-3 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </b-col>

        <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>

          <el-table-column min-width="180px" label="Berita Tanggal Acara">
            <div slot-scope="action">
              {{ formatDate(action.row.tanggalBeritaAcara) }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Provinsi">
            <div slot-scope="action">
              {{ action.row.provName}}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Kabupaten / Kota">
            <div slot-scope="action">
              {{ action.row.kabkotName }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Nama Industri">
            <div slot-scope="action">
              {{ action.row.compName }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Status">
            <div slot-scope="action">
              {{ action.row.tanggalBeritaAcara }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Berita Tanggal Kunjungan">
            <div slot-scope="action">
              {{ formatDate(action.row.tanggalKunjungan) }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="File Lampiran">
            <div slot-scope="action">
              <base-button type="primary" size="sm" icon>
                <a :href="action.row.lampiranBeritaAcara" style="color: white;" target="blank">Download</a>
              </base-button>
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Aksi">
            <div slot-scope="action">
              <base-button type="warning" size="sm" icon @click="editData(action.row)"> EDIT </base-button>
              <base-button type="danger" size="sm" icon> DELETE </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

            <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
            </span>
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
        >
        </base-pagination>
      </div>
    </div>

    <Modal
      :show="showModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeModal"
      size="lg"
    >
      <template v-slot:header>
        <strong>Edit Data Berita Acara Verifikasi Lapangan</strong>
      </template>
      <div>
        <div class="col-12 px-4 border-top pt-4">
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label"
              >Tanggal Berita Acara</label
            >
            <div class="col-lg-10">
              <base-input name="Tanggal Berita Acara" rules="required">
                <flat-picker
                  :config="provideSelect.flatPickerConfig"
                  class="form-control datepicker"
                  v-model="getDetail.tanggalBeritaAcara"
                />
              </base-input>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label"
              >Status Perusahaan</label
            >
            <div class="col-lg-10">
              <base-input name="Status Perusahaan" rules="required">
                <el-select v-model="getDetail.status" >
                  <el-option label="Tidak Terdaftar" value="tidak terdaftar" />
                  <el-option label="Terdaftar" value="terdaftar" />
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label"
              >Nama Perusahaan</label
            >
            <div class="col-lg-10">
              <base-input
                  name="Nama Perusahaan"
                  rules="required"
                  v-model="getDetail.compName"
                />
            </div>
            <!-- <div class="col-lg-10">
              <base-input
                v-if="form.isRegistered"
                name="Nama Perusahaan"
                rules="required"
              >
                <el-select
                  v-model="form.compID"
                  filterable
                  @change="getCompanyData"
                >
                  <el-option
                    v-for="company in data.compList"
                    :key="company._id"
                    :label="company.name"
                    :value="company.compID"
                  />
                </el-select>
              </base-input>
              <base-input
                v-else
                name="Nama Perusahaan"
                rules="required"
                v-model="form.detailOtherComp.compName"
              />
            </div> -->
          </div>
           <!-- <template v-if="!form.isRegistered"> -->
            <template>
            <div class="form-group row">
              <label class="col-lg-2 col-form-label form-control-label"
                >Provinsi</label
              >
              <div class="col-lg-10">
                <base-input name="Provinsi" rules="required">
                  <el-select v-model="getDetail.provName" @change="getCityDepentOnProvince(getDetail.provId)">
                    <el-option
                      v-for="option in provideSelect.province"
                      :key="option.company_province_id"
                      :label="option.company_province_name"
                      :value="option.company_province_id"
                    />
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-2 col-form-label form-control-label"
                >Kabupaten/Kota</label
              >
              <div class="col-lg-10">
                <base-input name="Kabupaten/Kota" rules="required">
                  <el-select v-model="getDetail.kabkotName" filterable>
                    <el-option
                      v-for="option in provideSelect.regency"
                      :key="option.company_regency_id"
                      :label="option.company_regency_name"
                      :value="option.company_regency_id"
                    />
                  </el-select>
                </base-input>
              </div>
            </div>
          </template>
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label"
              >Tanggal Kunjungan</label
            >
            <div class="col-lg-10">
              <base-input name="Tanggal Kunjungan" rules="required">
                <flat-picker
                  :config="provideSelect.flatPickerConfig"
                  class="form-control datepicker"
                  v-model="getDetail.tanggalKunjungan"
                />
              </base-input>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label"
              >File Lampiran</label
            >
            <div class="col-lg-10">
              <file-input
                  accept="application/pdf"
                  ref="lampiranBeritaAcara"
                  @change="uploadFile('lampiranBeritaAcara')"
                  />
                  <div v-if="getDetail.lampiranBeritaAcara" class="mt-3">
                    <a :href="getDetail.lampiranBeritaAcara" target="blank">Lihat File Terupload</a>
                  </div>
            </div>
          </div>
          <div class="form-group row px-3 float-right pb-4">
            <button class="btn btn-warning" @click="closeModal()">Batal</button>
            <button class="btn btn-primary" @click="onSubmit()">Simpan</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";
import swal from "sweetalert2";
import users from "./dummyBeritaAcaraTable";
import Modal from "@/components/Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileInput from "@/components/Inputs/FileInput";
import moment from "moment";


const flatPickerConfig = {
dateFormat: "U",
allowInput: true,
altInput: true,
altFormat: "d-m-Y H:i",
enableTime: true,
time_24hr: true,
defaultHour: 0,
mode: "range"
};


export default {
  mixins: [clientPaginationMixin],
  components: {
    FileInput,
    flatPicker,
    Modal,
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      getDetail:{},
      showModal:false,
      tableData: users,
      selectedRows: [],
      form: {
        tanggalBeritaAcara: null,
        tanggalKunjungan: null,
        isRegistered: false,
        compID: null,
        detailOtherComp: {
          compName: null,
        },
        provID: null,
        kabkotID: null,
        lampiranBeritaAcara: {
          path: null,
          upload: 0,
          progress: 0,
        },
      },
      provideSelect: {
      flatPickerConfig,
      provinsi:[],
      kabupatenKota:[],
      daftarPerusahaan:[],
      tmp:[],
      uploadChoosen:null,
      registrationType:[],
      compiencePoint:[],
      companyType:[],
      companyName:[],
      province:[],
      regency:[]
    },
    userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      province:"",
      regency:"",
      status:"",
      tanggalBeritaAcara:"",
      tanggalKunjungan:""
    },
    };
  },
  methods: {
    formatDate(dates) {
      return moment.unix(dates).format('YYYY-MM-DD hh:Ss');
    },
    getCityDepentOnProvince(data) {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }
      this.provideSelect.regency = [];
      // API.get(`region/regencies/province/${data}`)
      API.get(`web/data/admin/master/regency` , {headers})
        .then(({ data: content }) => {
          this.provideSelect.regency = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  getProvince() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('web/data/admin/master/province', {headers})
      .then(({data:content}) => {
        this.provideSelect.province = content.data
      })
      .catch((err) => {
        console.log(err);
      })
  },
    editData(get) {
      console.log(get,'-->');
      this.getDetail = get
      this.showModal = true
    },
    onSubmit() {
      console.log("submit");  
      this.showModal = false
    },
    closeModal() {
      console.log("close");
      this.showModal = false
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        type: "success",
        confirmButtonClass: "btn btn-success btn-fill",
      });
    },
    handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-info btn-fill",
      });
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swal.fire({
              title: "Deleted!",
              text: `You deleted ${row.name}`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    view(get) {
      this.$router.push("/pengumumanview");
      console.log("view");
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
