<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-atom"></i> Berita Acara Verifikasi Lapangan
      </h6>
    </h1>
    
    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>


                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Kabupaten kota -->
                <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota">
                    <select
                      class="form-control"
                      v-model="form.umum.compCity"
                    >
                      <option
                        v-for="(option, idx) in provideSelect.kabupatenKota"
                        :key="idx"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </base-input>
                </div> -->

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                

               

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
                
                
              </div>

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                <!-- <button
                  class="btn text-white"
                  style="background-color: #b51308;"
                  @click="downloadPDFNow"
                >
                  <i class="fa fa-solid fa-file-pdf mr-2" style="font-size: 20px;"></i>
                    Unduh PDF
                </button>
                <button
                  class="btn text-white"
                  style="background-color: #0a7641;"
                  @click="downloadExcelNow"
                >
                  <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                    Unduh Excel
                </button> -->
                <base-button
                  size="md"
                  type="primary"
                  @click="handleAdd()"
                  v-if="log_as == 'admin'"
                >
                  Tambah Data
                </base-button>
              </b-col>
            </b-row>
            </template>
              <!-- <BeritaAcaraTable/> -->

              <section id="mytable">
                  <el-table
                    :data="listData"
                    row-key="id"
                    header-row-class-name="thead-light"
                  >

                    <el-table-column label="No" type="index" width="100" />

                    <el-table-column min-width="150px"  label="Tanggal Berita Acara">
                      <div slot-scope="action">
                        {{ action.row.tgl_berita_acara }}
                      </div>
                    </el-table-column>

                    <el-table-column min-width="150px"  label="Nama Industri" v-if="log_as == 'admin'">
                        <div slot-scope="action">
                          {{ action.row.comp_name }}
                        </div>
                      </el-table-column>


                      <el-table-column min-width="110px"  label="Provinsi" v-if="log_as == 'admin'">
                        <div slot-scope="action">
                          {{ action.row.province_name }}
                        </div>
                      </el-table-column>

                      <el-table-column min-width="130px"  label="Kabupaten / Kota" v-if="log_as == 'admin'">
                        <div slot-scope="action">
                          {{ action.row.kabkot_name }}
                        </div>
                      </el-table-column>


                  

                    <!-- <el-table-column min-width="170px"  label="Status">
                      <div slot-scope="action">
                        {{ 'status none '}}
                      </div>
                    </el-table-column> -->

                    <el-table-column min-width="150px"  label="Tanggal Berita Kunjungan">
                      <div slot-scope="action">
                        {{ action.row.tgl_kunjungan }}
                      </div>
                    </el-table-column>

                    <el-table-column min-width="140px"  label="File Lampiran">
                      <div slot-scope="action">
                        <button class="btn btn-primary btn-sm">
                          <a :href="`https://${action.row.attachment_file_id}`" target="blank" class="text-white">Unduh</a>
                        </button>
                      </div>
                    </el-table-column>

                   

                    <el-table-column min-width="250px"  label="AKSI" v-if="log_as == 'admin'">
                      <div slot-scope="action">
                          <!-- edit -->
                          <base-button
                          @click="handleEdit(action.row)"
                          type="warning"
                          size="sm"
                          icon
                          >
                          UBAH DATA
                          </base-button>
                          <base-button
                          @click="handleDelete(action.row.id)"
                          type="danger"
                          size="sm"
                          icon
                          >
                          HAPUS
                          </base-button>
                      </div>
                    </el-table-column>
                  </el-table>

                  <div class="py-3">
                      <el-select
                        class="select-primary pagination-select mr-3"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                        @change="changeSizePage()"
                        >
                        <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                      </el-select>

                      <small class="card-category">
                      Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                      </small>
                  </div>

                  <div style="display: flex;justify-content: flex-end;">
                    <base-pagination
                        class="pagination-no-border"
                        :current="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total"
                        @change="paginationChanged($event)"
                    >
                    </base-pagination>
                  </div>
                </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Create Berita Acara -->
    <Modal
      :show="showModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeModal"
      size="lg"
    >
      <template v-slot:header>
        <strong>Tambah Berita Acara Verifikasi Lapangan</strong>
      </template>
      <div>
        <div class="col-12 px-4 border-top pt-4">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Tanggal Berita Acara</label>
                <div class="col-lg-10">
                  <base-input name="Tanggal Berita Acara" rules="required">
                    <flat-picker
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggalBeritaAcara"
                      
                    />
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Status Perusahaan</label>
                <div class="col-lg-10">
                  <base-input name="Status Perusahaan" rules="required">
                    <el-select v-model="userInput.status">
                      <el-option label="Tidak Terdaftar" value="notregister" />
                      <el-option label="Terdaftar" value="register" />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row" v-if="userInput.status == 'notregister'">
                <label class="col-lg-2 col-form-label form-control-label">Nama Perusahaan</label>
                <div class="col-lg-10">
                  <base-input name="OtherCompany" placeholder="Nama Perusahaan" rules="required"  v-model="userInput.notRegisterCompanyName"/>
                </div>
              </div>
    
                <!-- Provinsi -->
              <div class="form-group row" v-if="userInput.status == 'notregister'">
                <label class="col-lg-2 col-form-label form-control-label">Provinsi</label>
                <div class="col-lg-10">
                  <base-input name="Provinsi" rules="required">
                    <el-select v-model="userInput.province" filterable @change="getCityDepentOnProvince(userInput.province)">
                      <el-option
                        v-for="option in provideSelect.provinsi"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <!-- Kabupaten kota -->
              <div class="form-group row" v-if="userInput.status == 'notregister'">
                <label class="col-lg-2 col-form-label form-control-label">Kabupaten / Kota</label>
                <div class="col-lg-10">
                  <base-input name="Kabupaten / Kota" rules="required">
                    <el-select v-model="userInput.regency" filterable>
                      <el-option
                        v-for="option in provideSelect.kabupatenKota"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row" v-if="userInput.status == 'register'">
                <label class="col-lg-2 col-form-label form-control-label">Nama Perusahaan</label>
                <div class="col-lg-10">
                  <base-input name="Nama Perusahaan" rules="required">
                    <el-select v-model="userInput.companyName" filterable>
                      <el-option v-for="item in provideSelect.companyName" :label="item.name" :value="item.id" :key="item.id" />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Tanggal Kunjungan</label>
                <div class="col-lg-10">
                  <base-input name="Tanggal Kunjungan" rules="required">
                    <flat-picker
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggalKunjungan"
                    />
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">File Lampiran</label>
                <div class="col-lg-10">
                  <base-input name="File Lampiran"  >
                      <file-input
                      accept="application/pdf"
                      ref="berita_acara_attachment_file"
                      @change="uploadFile('berita_acara_attachment_file','berita_acara_attachment_file')"
                      ></file-input>
                  </base-input>
                </div>
              </div>
    
              <div class="form-group row px-3 float-right pb-4">
                <button class="btn btn-primary" type="submit">Simpan</button>
                <button class="btn btn-danger" TYPE="button" @click="closeModal()">Batal</button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </Modal>

    <!-- Update Berita Acara -->
    <Modal
      :show="showUpdateModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeUpdateModal"
      size="lg"
    >
      <template v-slot:header>
        <strong>Perbaharui Berita Acara Verifikasi Lapangan</strong>
      </template>
      <div>
        <div class="col-12 px-4 border-top pt-4">
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label">Tanggal Berita Acara</label>
            <div class="col-lg-10">
              <base-input name="Tanggal Berita Acara" rules="required">
                <flat-picker
                  :config="provideSelect.flatPickerConfig"
                  class="form-control datepicker"
                  v-model="userEdit.tanggalBeritaAcara"
                  
                />
              </base-input>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label">Status Perusahaan</label>
            <div class="col-lg-10">
              <base-input name="Status Perusahaan" rules="required">
                <el-select v-model="userInput.status">
                  <el-option label="Tidak Terdaftar" :value="false" />
                  <el-option label="Terdaftar" :value="true" />
                </el-select>
              </base-input>
            </div>
          </div> -->

              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Nama Perusahaan</label>
                <div class="col-lg-10">
                  <base-input name="OtherCompany" placeholder="Nama Perusahaan" rules="required" disabled v-model="userEdit.companyName"/>
                </div>
              </div>
    
                <!-- Provinsi -->
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Provinsi</label>
                <div class="col-lg-10">
                  <base-input name="Provinsi" rules="required" disabled>
                    <el-select v-model="userEdit.province" disabled filterable @change="getCityDepentOnProvince(userInput.province)">
                      <el-option
                        v-for="option in provideSelect.provinsi"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <!-- Kabupaten kota -->
              <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Kabupaten / Kota</label>
                <div class="col-lg-10">
                  <base-input name="Kabupaten / Kota" rules="required" >
                    <el-select v-model="userEdit.regency" filterable disabled>
                      <el-option
                        v-for="option in provideSelect.kabupatenKota"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
    
              <!-- <div class="form-group row">
                <label class="col-lg-2 col-form-label form-control-label">Nama Perusahaan</label>
                <div class="col-lg-10">
                  <base-input name="Nama Perusahaan" rules="required">
                    <el-select v-model="userInput.companyName">
                      <el-option v-for="item in provideSelect.companyName" :label="item.name" :value="item.id" :key="item.id" />
                    </el-select>
                  </base-input>
                </div>
              </div> -->

          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label">Tanggal Kunjungan</label>
            <div class="col-lg-10">
              <base-input name="Tanggal Kunjungan" rules="required">
                <flat-picker
                  :config="provideSelect.flatPickerConfig"
                  class="form-control datepicker"
                  v-model="userEdit.tanggalKunjungan"
                />
              </base-input>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label">File Lampiran</label>
            <div class="col-lg-10">
              <base-input name="File Lampiran">
                  <file-input
                  accept="application/pdf"
                  ref="berita_acara_attachment_file_update"
                  @change="uploadFile('berita_acara_attachment_file_update','berita_acara_attachment_file_update')"
                  ></file-input>
              </base-input>
              <a :href="`https://${userEdit.lampiran_path}`" target="blank">
              <span v-if="userEdit.lampiran_path">Lihat File Terupload</span>
              <span v-else>Belum Mengirim file</span>
              </a>
            </div>
          </div>


          <div class="form-group row px-3 float-right pb-4">
            <button class="btn btn-primary" @click="onUpdate()">Ubah Data</button>
            <button class="btn btn-danger" @click="closeUpdateModal()">Batal</button>
          </div>
        </div>
      </div>
    </Modal>

     <!-- Loading Screen -->
     <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal PDF -->
    <Modal
      :show="showDownloadModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModal = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div id="printBos">
              <div>
                <div style="display: flex;justify-content: space-between;">
                  <div>
                    <div style="display: flex;justify-content: center;align-items: center;">
                      <div style="width: 50px;height: 50px;">
                        <img src="/img/brand/img-logo-color.png" alt="" style="width: 100%;">
                      </div>
                      <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                    </div>
                  </div>
                  <div>
                    <h2>{{ pdfName }}</h2>
                    <small><b>Laporan Tidak Normal</b></small><br>
                    <small>Data ini dibuat pada 12-20-2024</small>
                  </div>
                </div>
                <table class="table mt-3">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Keterangan</th>
                      <th class="text-white">Dibuat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload" :key="idx">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name  }}</td>
                      <td>{{ item.message }}</td>
                      <td>{{ item.created_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


            <!-- <div class="mt-5">
              <button class="btn btn-success" @click="exportExcel">Unduh Excel</button>
            </div> -->

            <!-- <ReportExcel/> -->


          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary" @click="print">Unduh PDF</button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div style="padding:10px;overflow-x: scroll;" >
                  <table class="table mt-3" ref="exportable_table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Keterangan</th>
                      <th class="text-white">Dibuat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload" :key="idx">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name  }}</td>
                      <td>{{ item.message }}</td>
                      <td>{{ item.created_at }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from "@/api/base_url.js";
import { Select, Option, Table, TableColumn } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import BeritaAcaraTable from '../Tables/BeritaAcaraTable.vue';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileInput from "@/components/Inputs/FileInput";
// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";


export default {
components: {
  FileInput,
  Modal,
  BaseHeader,
  flatPicker,
  DataSparingChart,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn,
  BeritaAcaraTable
},
mounted() {
  // this.getCompany()

  // new
  this.log_as = localStorage.getItem("as");
  this.getData()
  this.getCompanyType()
  this.getCompanyName()
  this.getProvince()
  this.getCompliencePoint()
  
},
data() {
  return {
    attachment_file_id: "",
    pdfName:"",
    listDataDownload: [],
    listDataCompliencePoints: [],
    filterStatusPendaftaran: "",
    selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val: "Menunggu Validasi",
          name:"Menunggu"
        },
        {
          val: "Diterima",
          name:"Disetujui"
        },
        {
          val: "Ditolak",
          name:"Ditolak"
        },
    ],
    userInput: {
    tanggal: "",
    registrationType:"SP",
    titikPenaatan:"",
    companyType:"",
    companyName:"",
    notRegisterCompanyName:"",
    province:"",
    kabupatenKota:"",
    regency:"",
    tanggalBeritaAcara:"",
    tanggalKunjungan:"",
    status: null
    },
    provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          defaultHour: 0,
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
    },
    form: {
      umum: {
        compName: null,
        compNameID: null,
        compType: null,
        compCity: "",
        compProvince: "",
      },
    },
    isLoading: false,
    showDownloadModal:false,
    showDownloadModalCSV:false,
      // end of filter
    formDataBeritaAcara : new FormData(),
    pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
    },
    log_as:"",
    showModal:false,
    showUpdateModal:false,
    listData: [],
    userEdit: {
      id:"",
      tanggalBeritaAcara:"",
      tanggalKunjungan:"",
      status:"",
      lampiran:"",
      province:"",
      regency:"",
      companyName:""
    }
  };
},
methods: {
    uploadFile(field){
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.uploading = true
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data
          let value = data.id
          this.$set(this.userInput, field, value);
          // this.attachment_file_id = value
          // console.log(`${data}:`, this.userInput[field]);
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
          Swal.fire(
                  "Terjadi Kesalahan",
                  "Mohon upload ulang kembali",
                  "error"
          );
        });
    },
    getData() {
      this.isLoading = true
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      API.get(`berita-acara?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }

              this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comp-types', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }

    const queryParams = this.queryParams();


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

  
   

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`berita-acara` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    queryParams(){
      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = {
        page: this.pagination.currentPage,
        page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        is_export: true,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        API.get(`berita-acara` , { params, headers})
          .then(({data}) => {
            if (data.data == null) {
                this.listDataDownload = []
                this.isLoading = false
              } else {
                this.listDataDownload = data.data
                this.isLoading = false
              }
          })
          .catch((err) => {
            this.isLoading = false
            console.log(err);
          })
    },

    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`stations` , {headers})
      .then(({data:content}) => {
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    downloadPDFNow() {
      this.getDataDownload()
      this.showDownloadModal = true      
    },
    downloadExcelNow() {
      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    print() {
      var element = document.getElementById("printBos");
      var opt = {
        margin: 0.5,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      }
      // New Promise-based usage:
      html2pdf().set(opt).from(element).save();
      Swal.fire('Data di Unduh','','success')
      this.showDownloadModal = false
    },
    ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModalCSV = false
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
            XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
    },
    reportTableState() {
        return []
    },
   // End of Filter
  
  handleAdd() {
    this.showModal = true
  },
  handleEdit(row) {
    this.showUpdateModal = true

    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get(`berita-acara/${row.id}`, {headers})
      .then(({ data:content }) => {
        console.log(content,'uhuuu');
        this.userEdit.id = content.data.id
        this.userEdit.tanggalBeritaAcara = content.data.tgl_berita_acara
        this.userEdit.tanggalKunjungan = content.data.tgl_kunjungan
        this.userEdit.lampiran_path = content.data.attachment_file_id
        this.userEdit.province = content.data.province_name
        this.userEdit.regency = content.data.kabkot_name
        this.userEdit.companyName = content.data.comp_name
      })
      .catch((err) => {
        console.log(err);
      });
  },
  handleDelete(id) {
    Swal.fire({
        title: "Hapus Berita Acara?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          API.delete(`berita-acara/${id}` , {headers})
          .then(({data}) => {
            Swal.fire("Berhasil","Berita Acara dihapus","success");
            this.getData()
          })
          .catch((err) => {
            Swal.fire("Gagal Menghapus data", '', "error");
          })
        }
      });
  },
  closeModal() {
    this.showModal = false
  },
  closeUpdateModal() {
    this.showUpdateModal = false
  },
  changeSizePage() {
      this.getData()
  },
  paginationChanged(page) {
    this.pagination.currentPage = page;
    this.getData()
  },
  
  onSubmit() {


    Swal.fire({
        title: "Kirim Berita Acara?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "KIRIM",
        cancelButtonText: "BATAL"
      }).then((result) => {
        console.log(this.attachment_file_id,'ini gess');
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          if (!this.attachment_file_id) {
            return Swal.fire('Anda belum mengupload File','','error')
          }

          let payload = {
            attachment_file_id: this.attachment_file_id,
            comp_id: this.userInput.companyName,
            kabkot_id: this.userInput.regency,
            other_comp_name: this.userInput.notRegisterCompanyName,
            prov_id: this.userInput.province,
            tgl_berita_acara: this.userInput.tanggalBeritaAcara,
            tgl_kunjungan: this.userInput.tanggalKunjungan
          }

       

          API.post('berita-acara' , payload, {headers})
          .then(({data}) => {
            Swal.fire("Berhasil","Berita Acara dibuat","success");
            this.closeModal()
            this.getData()
            this.userInput.tanggalBeritaAcara = ""
            this.userInput.tanggalKunjungan = ""
            this.userInput.companyName = ""
            this.userInput.notRegisterCompanyName = ""
            this.userInput.status = null
            this.attachment_file_id = ""
          })
          .catch((err) => {
            Swal.fire("Pendaftaran Gagal", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
          })
        }
      });
  },
  onUpdate() {
    Swal.fire({
        title: "Perbaharui Berita Acara?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "UBAH",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          let payload = {
            attachment_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
            tgl_berita_acara: this.userEdit.tanggalBeritaAcara,
            tgl_kunjungan: this.userEdit.tanggalKunjungan
          }


          API.put(`berita-acara/${this.userEdit.id}` , payload, {headers})
          .then(({data}) => {
            Swal.fire("Pembaharuan Berhasil","Berita Acara diperbaharui","success");
            this.closeUpdateModal()
            this.getData()
            this.userEdit.id = ""
            this.userEdit.tanggalBeritaAcara = ""
            this.userEdit.tanggalKunjungan = ""
            this.userInput.companyName = ""
            this.formDataBeritaAcara = new FormData()
          })
          .catch((err) => {
            Swal.fire("Pendaftaran Gagal", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
          })
        }
      });
  },
  uploadFilePermissionLater(category, field) {
    if (this.formDataBeritaAcara.get(field) !== null) {
      this.formDataBeritaAcara.delete(field)
      this.formDataBeritaAcara.append(field, this.$refs[field].files[0]);
    } else {
      this.formDataBeritaAcara.append(field, this.$refs[field].files[0]);
    }
    },
},
};
</script>


  